<template>
    <div>
        <div class="card card-shadow">
            <div class="card-inner">
                <div class="row">
                    <div class="col-12" v-if="contacts">
                        <vue-good-table v-if="contacts"
                            :columns="columns"
                            :rows="contacts"
                            styleClass="vgt-table condensed tblhov"
                            :search-options="{ enabled: true, placeholder: 'Search Contacts'}"
                            :sort-options="{ enabled: true, field: 'id', type:'desc'}"
                            @on-cell-click="goToContact"
                            :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: 50,
                            position: 'bottom',
                            perPageDropdown: [50,100,150],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: 'Next',
                            prevLabel: 'Prev',
                            rowsPerPageLabel: 'Rows per page',
                            ofLabel: 'of',
                            pageLabel: 'page', // for 'pages' mode
                            allLabel: 'All',
                            }"
                        >
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'createdDate'">{{ $moment(props.row.createdDate).format("H:mm a DD/MM/YYYY") }}</span>
                        </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        accountId: Number
    },
    data() {
        return {
            contacts: [],
            accounts: [],
            columns: [
            {
              label: 'Name',
              sortable: false,
              field: 'contactName',
            }, {
              label: 'Phone Number',
              sortable: false,
              field: 'phoneNumber'
            }, {
              label: 'Mobile',
              sortable: false,
              field: 'mobile'
            },{
              label: 'Email',
              sortable: false,
              field: 'email'
            }, {
              label: 'Job Title',
              sortable: false,
              field: 'jobTitle'
            }, {
              label: 'Created Date',
              field: 'createdDate'
            }
            ],
        }
    },
    created() {
        this.getContactsForAccount()
    },
    methods: {
        getContactsForAccount() {
            this.$http.get('/accounts/Get_Account_Contacts/' + this.accountId)
            .then((response) => {
                this.contacts = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        goToContact(props) {
            this.$router.push({ name: 'contact', query: { contactId: props.row.id }  })
        }
    }
}
</script>

<style>

</style>