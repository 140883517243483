<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <div class="row g-3 align-center">
                    <div class="col-lg-5">
                        <div class="form-group">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="row g-3 align-center">
                    <div class="col-lg-5">
                        <div class="form-group">
                            <span class="form-label float-right" for="accountOwner"><em class="icon ni ni-piority"/> Default Priority</span>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="form-group">
                            <div class="form-control-wrap">
                                <a-select v-model="account.defaultTicketPriority" class="invisibox" style="width: 100%;" @change="updateAccount()" >
                                    <a-select-option v-for="p in priorities" :key="p.id">
                                        {{ p.name }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row g-3 align-center">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <span class="form-label float-right" for="accountOwner"><em class="icon ni ni-piority-fill"/> Default Issue Type</span>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="form-group">
                            <div class="form-control-wrap">
                                <a-select v-model="account.defaultTicketIssueType" class="invisibox" style="width: 100%;" @change="updateAccount()">
                                    <a-select-option v-for="i in issueTypes" :key="i.id">
                                        {{ i.name }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-1">

            </div>
            <div class="col-10">
                <div class="row">
                    <div class="col-12">
                        <a-button disabled="disabled" v-if="!accountId" class="btn btn-dark disabled" style="padding-bottom: 10px">Please save account to Assign New Issue Type Allowance</a-button>
                        <a-button v-if="showExpired === true" @click="changeExpiredView('hideExpired')" class="btn btn-dark float-right">Hide Expired</a-button>
                        <a-button v-if="showExpired === false" @click="changeExpiredView('showExpired')" class="btn btn-dark float-right">Show Expired</a-button>
                        <a-button v-if="accountId" class="btn btn-dark" style="padding-bottom: 10px" @click="showAssignIssueType = true">Assign New Allowance</a-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <vue-good-table v-if="accountIssueTypeAllowances"
                        :columns="columns"
                        :rows="accountIssueTypeAllowances"
                        styleClass="vgt-table condensed tblhov"
                        >
                        <template slot="table-row" slot-scope="props" >
                          <div  v-if="props.column.field == 'createdDate'" >
                            {{ $moment(props.row.createdDate).format('DD/MM/YYYY')}}
                          </div>
                          <div  v-else-if="props.column.field == 'expiredDate' && props.row.expiredDate" >
                            {{ $moment(props.row.expiredDate).format('DD/MM/YYYY')}}
                          </div>
                          <div v-else-if="props.column.field === 'actions'">
                            <div>

                                <a-popover placement="left">
                                    <template slot="content">
                                        View Breakdown
                                    </template>
                                    <a-button class="btn-sm btn-dark text-center" style="font-size: 16px; border: none; margin-right:5px;" @click="showBreakdown(props.row)">
                                        <em class="ni ni-icon ni-eye"/>
                                    </a-button>
                                </a-popover>
                                <a-popover placement="right" v-if="!props.row.expiredDate">
                                    <template slot="content">
                                        Expire Allowance
                                    </template>
                                    <a-popconfirm
                                        title="Are you sure expire this allowance?"
                                        ok-text="Yes"
                                        cancel-text="No"
                                        @confirm="deleteAccountIssueType(props.row)"
                                        @cancel="cancel"
                                    >
                                        <a-button class="btn-sm btn-danger text-center" style="font-size: 16px; border: none;">
                                            <em class="ni ni-icon ni-trash-alt"/>
                                        </a-button>
                                    </a-popconfirm>
                                </a-popover>
                            </div>
                          </div>
                        </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <a-modal v-model="showAssignIssueType" title="Assign New Allowance">
            <div class="row">
                <div class="col-12">
                    <label class="form-label">Issue Type</label>
                    <a-select style="width:100%" v-model="assignIssueType.tenantTicketIssueTypeId">
                        <a-select-option v-for="it in issueTypes" :key="it.id" @click="selectIssueType(it)">
                            {{ it.name }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-12" v-if="selectedIssueType != null">
                    <small class="pl-2">This issue type uses the following parameters:</small><br>
                    <small class="pl-2">Unit: {{selectedIssueType.unitName}}</small><br>
                    <small class="pl-2">Allowance Time Span: {{selectedIssueType.allowanceName}}</small>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="form-label">Allowance</label>
                    <a-input v-model="assignIssueType.allowance"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="form-label">Expiry Date</label>
                    <p>Please leave blank if this is a rolling allowance.</p>
                    <a-date-picker style="width:100%;" v-model="assignIssueType.expiryDate"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="form-label">Notes</label>
                    <a-textarea style="width:100%;" v-model="assignIssueType.notes"/>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="addAccountIssueType()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
        <a-modal v-model="showBreakdownModal" width="80%" title="Issue Type Allowance Breakdown">
            <div class="row">
                <div class="col-12">
                    <vue-good-table v-if="allowanceBreakdown"
                    :columns="allowanceColumns"
                    :rows="allowanceBreakdown"
                    styleClass="vgt-table condensed tblhov"
                    >
                    <template slot="table-row" slot-scope="props" >
                        <div  v-if="props.column.field == 'startDate'" >
                        {{ $moment(props.row.startDate).format('DD/MM/YYYY')}}
                        </div>
                    </template>
                    </vue-good-table>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn btn-dark" @click="showBreakdownModal = false"> Close</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>

export default {
    props: {
        accountId: Number
    },
    data(){
        return {
            showExpired: false,
            account: null,
            priorities: null,
            accountIssueTypeAllowances: null,
            issueTypes: [],
            assignIssueType: {},
            selectedIssueType: null,
            accountIssueTypeUsage: null,
            showAssignIssueType: false,
            showBreakdownModal: false,
            columns: [
                {
                    label: 'Issue Type',
                    align: 'start',
                    sortable: false,
                    field: 'issueType',
                },
                { label: 'Created Date', field: 'createdDate'},
                { label: 'Allowance', field: 'allowance'},
                { label: 'Expired Date', field: 'expiredDate'},
                { label: 'Notes', field: 'notes', width: '30%'},
                { label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center'},
            ],
            allowanceColumns: [
                {
                    label: 'Issue Type',
                    align: 'start',
                    sortable: false,
                    field: 'issueType',
                },
                { label: 'Issue Unit', field: 'issueType'},
                { label: 'Start Date', field: 'startDate'},
                { label: 'Allowance', field: 'allowance'},
                { label: 'Remaining', field: 'remaining'},
                { label: 'Notes', field: 'notes', width: '30%'},
            ],
            allowanceBreakdown: null
        }
    },
    created() {
        this.getLists()
        if (this.accountId != null) {
            this.getAccount(this.accountId)
            this.getAccountIssueTypes()
        } else {
            this.getAccountIssueTypes()
        }
    },
    methods: {
        cancel() {
            return false
        },
        selectIssueType(it) {
            this.selectedIssueType = it
        },
        changeExpiredView(view) {
            if (view === 'hideExpired') {
                this.showExpired = false
                this.getAccountIssueTypes()
            } else {
                this.showExpired = true
                this.getAccountIssueTypes()
            }
        },
        getAccountIssueTypes() {
            let search  = this.accountId
            if (search) {
                 this.$http.get('/accounts/Get_AccountIssueTypeAllowances/' + search)
                .then((response) => {
                    this.accountIssueTypeAllowances = response.data
                    if (this.showExpired === false) {
                        for (let i = 0; i < this.accountIssueTypeAllowances.length; i++) {
                            if(this.accountIssueTypeAllowances[i].expiredDate) {
                                this.accountIssueTypeAllowances.splice(i, 1)
                            }
                        }
                    }
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })  
            }
        },
        getLists()  {
            this.$http.get('/settings/Get_Tenant_TicketIssueTypes')
            .then((response) => {
                this.issueTypes = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/settings/Get_Tenant_TicketPriorities')
            .then((response) => {
                this.priorities = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        showEditIssueType(props){
            this.assignIssueType = props.row
            this.showAssignIssueType = true
        },
        addAccountIssueType(){
            this.assignIssueType.accountId = this.accountId
            this.$http.post('/accounts/Add_AccountIssueTypeAllowance/', this.assignIssueType)
            .then(() => {
                this.getAccountIssueTypes()
                this.$message.success('Allowance Assigned')
                this.showAssignIssueType = false
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        deleteAccountIssueType(props){
            this.$http.post('/accounts/Expire_AccountIssueTypeAllowance/', props)
            .then(() => {
                this.getAccountIssueTypes()
                this.$message.success('Allowance Expired')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getAccount(id) {
            let uri  = id ? ('/accounts/Get_Account/' + id) : '/accounts/Get_Account/' 
            this.$http.get(uri)
            .then((response) => {
                this.account = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        updateAccount() {
            this.$http.post('/accounts/Update_Account', this.account)
            .then(() => {
                this.$message.success('Account Updated')
                this.getAccount(this.accountId)
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        showBreakdown(props) {
            this.$http.get('/accounts/Get_AccountIssueTypeAllowanceBreakdown/' + props.id)
            .then((response) => {
                this.allowanceBreakdown = response.data
                this.showBreakdownModal = true
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>