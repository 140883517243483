<template>
    <div class="row g-gs">
        <div class="col-lg-12">
            <div class="card h-100">
                <div class="card-inner" v-if="account">
                    <div class="row">
                        <div class="col-md-6">
                            <h5 class="card-title"><em class="icon ni ni-building"/> Account Details</h5>
                        </div>
                        <div class="col-md-6">
                            <button  v-on:click="saveAccount()" class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Save</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <span class="form-label float-right" for="accountOwner"><em class="icon ni ni-user"/> Account Owner</span>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                        <a-select v-model="account.accountOwnerId"
                                            show-search
                                            class="invisibox"
                                            placeholder="Select a user"
                                            :class="{'is-error' : $v.account.accountOwnerId.$invalid }"
                                            option-filter-prop="children"
                                            :filter-option="filterOption"
                                            style="width: 100%">
                                             <a-select-option :value="null" > Not selected </a-select-option> 
                                                <a-select-option v-for="user in users" v-bind:key="user.id">
                                                    {{ user.firstName + ' ' + user.lastName }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="accountName"><em class="icon ni ni-building"/> Account Name</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                         <a-input v-model="account.accountName" placeholder="Account Name" type="text" class="invisibox" :class="{'is-error' : $v.account.accountName.$invalid }" id="accountName"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="source"><em class="icon ni ni-notes-alt"/> Account Type</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select placeholder="Select Account Type" class="invisibox" v-model="account.accountTypeId" style="width: 100%">
                                             <a-select-option :value="null" > Not selected </a-select-option> 
                                                <a-select-option v-for="accountType in accountTypes" v-bind:key="accountType.id">
                                                    {{ accountType.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                              <label class="form-label float-right" for="source"><em class="icon ni ni-notes-alt"/> Industry Type</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select placeholder="Select industry Type" show-search option-filter-prop="children" :filter-option="industryFilter" class="invisibox" v-model="account.industryTypeId" style="width: 100%">
                                             <a-select-option :value="null" > Not selected </a-select-option> 
                                                <a-select-option v-for="industryType in industryTypes" v-bind:key="industryType.id">
                                                    {{ industryType.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="source"><em class="icon ni ni-building"/> Parent Account</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select class="invisibox" v-model="account.parentAccountId"
                                                show-search
                                                placeholder="Select a Account"
                                                option-filter-prop="children"
                                                :filter-option="filterOption"
                                                style="width: 100%">
                                             <a-select-option :value="null" > Not selected </a-select-option> 
                                                    <a-select-option v-for="account in accounts" v-bind:key="account.id">
                                                        {{ account.accountName }}
                                                    </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                         <label class="form-label float-right" for="source"><em class="icon ni ni-globe"/> Source</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select placeholder="Select Source" class="invisibox" v-model="account.sourceId" style="width: 100%">
                                             <a-select-option :value="null" > Not selected </a-select-option> 
                                                <a-select-option v-for="source in sources" v-bind:key="source.id">
                                                    {{ source.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <div class="row g-3">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="tel"><em class="icon ni ni-call"/> Tel No</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="-" type="text" v-model="account.phoneNumber" class="invisibox" id="tel"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="websiteUri"><em class="icon ni ni-globe"/> Website</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="-" type="text" v-model="account.websiteUri" class="invisibox" id="websiteUri"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="employees"><em class="icon ni ni-user"/> No of Employees</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="-" type="text" v-model="account.employeeCount" class="invisibox" id="employees"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="companyReg"><em class="icon ni ni-building"/> Company Reg No</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="-" type="text" v-model="account.companyRegistrationNumber" class="invisibox" id="companyReg"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="businessOwnership"><em class="icon ni ni-building"/> Business Ownership</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select placeholder="Select Business Ownership" class="invisibox" v-model="account.businessOwnershipId" style="width: 100%">
                                            <a-select-option :value="null" > Not selected </a-select-option> 
                                                <a-select-option v-for="businessOwnership in businessOwnerships" v-bind:key="businessOwnership.id">
                                                    {{ businessOwnership.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="referrer"><em class="icon ni ni-link-alt"/> Referrer</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="-" type="text" v-model="account.referrer" class="invisibox" id="referrer"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="preview-hr">
                    <div class="row"> 
                       <div class="col-md-6">  
                            <div class="row g-3">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                            <label class="form-label float-right" for="referrer"><em class="icon ni ni-map-pin"/> Billing Address</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Line 1" type="text" v-model="account.billingStreet" class="invisibox" :class="{'is-error' : $v.account.billingStreet.$invalid }" id="billingStreet"/>
                                            <a-input placeholder="Line 2" type="text" v-model="account.billingProvince" class="invisibox address" id="billingProvince"/>
                                            <a-input placeholder="Town/City" type="text" v-model="account.billingCity" class="invisibox address" :class="{'is-error' : $v.account.billingCity.$invalid }" id="billingCity"/>
                                            <a-input placeholder="Postcode" type="text" v-model="account.billingCode" class="invisibox address" :class="{'is-error' : $v.account.billingCode.$invalid }" id="billingCode"/>
                                            <a-input placeholder="County" type="text" v-model="account.billingCountry" class="invisibox address" :class="{'is-error' : $v.account.billingCountry.$invalid }" id="billingCountry"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row g-3">
                                <div class="col-3">
                                    <div class="form-group">
                                            <label class="form-label float-right" for="shippingAddress"><em class="icon ni ni-map-pin"/> Shipping Address</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Line 1" type="text" v-model="account.shippingStreet" class="invisibox"  id="shippingStreet"/>
                                            <a-input placeholder="Line 2" type="text" v-model="account.shippingProvince" class="invisibox address" id="shippingProvince" />
                                            <a-input placeholder="Town/City" type="text" v-model="account.shippingCity" class="invisibox address"  id="shippingCity" />
                                            <a-input placeholder="Postcode" type="text" v-model="account.shippingCode" class="invisibox address"  id="shippingPostcode" />
                                            <a-input placeholder="County" type="text" v-model="account.shippingCountry" class="invisibox address"  id="shippingCounty"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="preview-hr">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="card-title"><em class="icon ni ni-coins"/> Billing Details</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right"><em class="icon ni ni-call"/> Payment Methods</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select placeholder="Select Payment Method" class="invisibox" v-model="account.paymentMethodId" style="width: 100%">
                                            <a-select-option :value="null" > Not selected </a-select-option> 
                                                <a-select-option v-for="paymentMethod in paymentMethods" v-bind:key="paymentMethod.id">
                                                    {{ paymentMethod.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="businessContact"><em class="icon ni ni-user-alt-fill"/> Billing Contact</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select  class="invisibox" v-model="account.billingContactId"
                                                show-search
                                                placeholder="Select a Contact"
                                                option-filter-prop="children"
                                                :filter-option="filterOption"
                                                style="width: 100%">
                                                <a-select-option :value="null" > Not selected </a-select-option> 
                                                <a-select-option v-for="contact in contacts" v-bind:key="contact.id">
                                                    {{ contact.firstName + ' ' + contact.lastName }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row g-3">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="description"><em class="icon ni ni-article"/> Description</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="-" type="textarea" v-model="account.description" class="form-control invisibox" id="description"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="preview-hr">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="card-title"><em class="icon ni ni-ticket-alt"/> Issue Allowances</h5>
                            <div class="text-center" v-if="!accountId">
                                Please save the account to access Issue Allowances.
                            </div>
                        </div>
                    </div>
                    <accountDeskDetails :accountId="this.accountId"/>
                    <div class="row">
                        <div class="col-12">
                            <button v-on:click="saveAccount()" class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Save</button>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</template>

<script>
import accountDeskDetails from '@/components/accounts/account/accountDeskDetails.vue'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
    components: { accountDeskDetails },
      props: {
        accountId: Number,
    },
    validations: {
        account: {
            accountOwnerId: {
                required
            },
            accountName: {
                required,
                minLength: minLength(1)
            },
            billingStreet: {
                required,
                minLength: minLength(1)
            },
            billingCity: {
                required,
                minLength: minLength(1)
            },
            billingCode: {
                required,
                minLength: minLength(1)
            },
            billingCountry: {
                required,
                minLength: minLength(1)
            }
        }
    },
    data(){
        return {
            account: {
                accountOwnerId: null,
            },
            dateFormat: 'DD/MM/YYYY',
            industryFilter: '',
            industryTypes: [],
            sources: [],
            paymentMethods: [],
            accountTypes: [],
            businessOwnerships: [],
            users: [],
            accounts: [],
            contacts: [],
            issueTypes: [],
            assignIssueTypeId: null,
            accountDeskDetails: null,
        }
    },
    created() {
        this.getLists()
        if (this.accountId != null) {
            this.getAccount(this.accountId)
        } else {
            this.getAccount()
        }
        
    },
    methods: {
        saveAccount () {
            if (this.account.id != null)    {
                if(this.$v.account.$invalid){
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.updateAccount()
                }
            } 
            else {
                if(this.$v.account.$invalid) {
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.saveNewAccount()
                }
            }
        },
        saveNewAccount() {
            this.$http.post('/accounts/Add_Account', this.account)
            .then(() => {
                this.$message.success('Account Created')
                this.$router.push({ name:'accounts' })
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateAccount() {
            this.$http.post('/accounts/Update_Account', this.account)
            .then(() => {
                this.$message.success('Account Updated')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        getAccount(id) {
            let uri  = id ? ('/accounts/Get_Account/' + id) : '/accounts/Get_Account/' 
            this.$http.get(uri)
            .then((response) => {
                this.account = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getLists(){
            this.$http.get('/lists/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/accounts/get_Accounts')
            .then((response) => {
                this.accounts = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/contacts/get_Contacts')
            .then((response) => {
                this.contacts = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/industryTypes')
            .then((response) => {
                this.industryTypes = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/source')
            .then((response) => {
                this.sources = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/paymentMethods')
            .then((response) => {
                this.paymentMethods = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/accountTypes')
            .then((response) => {
                this.accountTypes = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/businessOwnership')
            .then((response) => {
                this.businessOwnerships = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        
    }
}
</script>

<style scoped>
.form-label {
    padding-top: 6px
}
.address {
    margin-top: 10px;
}
</style>