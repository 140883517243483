<template>
 <div class="nk-block">
        <a-breadcrumb style="padding-bottom: 10px; fontSize: 15px">
          <router-link :to="{name: 'accounts'}"><a-breadcrumb-item>Back To Accounts</a-breadcrumb-item></router-link>
          <a-breadcrumb-item>{{account.accountName}}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="card card-bordered">
            <div class="card-inner-group">
               <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="Details">
                  <accountForm :accountId="accountId"> </accountForm>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Contacts">
                  <accountContacts :accountId="accountId"></accountContacts>
                </a-tab-pane>
                <a-tab-pane key="3" tab="Notes" force-render>
                  <div class="row g-gs">
                      <div class="col-lg-12">
                          <div class="card   h-100">
                            <div class="card-inner">
                              <div v-if="accountId">
                                <notes  
                                  :id="accountId"
                                  :entityName="'account'"
                                  :getEndpoint="'/accounts/Get_Account_Notes/' + accountId" 
                                  :saveEndpoint="'/accounts/Save_Account_Note/'"
                                  :deleteEndpoint="'/accounts/Delete_Account_Note/'"></notes>
                              </div>
                              <div v-else>
                                <p class="text-center">Please save the account before adding notes.</p>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane key="4" tab="Attachments">
                  <div v-if="accountId">
                    <attachments  
                      :id="accountId"
                      :entityName="'account'"
                      :getEndpoint="'/accounts/Get_Account_Attachments/' + accountId">
                    </attachments>
                    </div>
                    <div v-else>
                      <p class="text-center">Please save the account before adding attachments.</p>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="5" tab="Tickets" v-if="$hasTenantModule(1)">
                  <div v-if="accountId">
                    <tickets :id="accountId"/>
                    </div>
                    <div v-else>
                      <p class="text-center">Please save the account before viewing tickets.</p>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="6" tab="Reports" v-if="$hasTenantModule(1)">
                  <reports :id="accountId"></reports>
                </a-tab-pane>
              </a-tabs>
            </div><!-- .card-inner-group -->
        </div><!-- .card -->
    </div>
</template>

<script>
import accountForm from '@/components/accounts/account/accountdetails.vue'
import accountContacts from '@/components/accounts/account/accountContacts.vue'
import notes from '@/components/generic/notes'
import attachments from '@/components/generic/attachments'
import tickets from '@/components/accounts/account/accountTickets.vue'
import reports from '@/components/accounts/account/accountreports.vue'

export default {
  data () {
    return {
      accountId: null,
      account: {}
    }
  },
  components: { accountForm, notes, attachments, tickets, accountContacts, reports },
  created(){
    this.getView()
    if (this.$route.query.accountId != null){
      this.accountId = parseInt(this.$route.query.accountId)
      this.getAccount()
    }
  },
  methods: {
    getView() {
      this.$http.get('/ViewAccess/account')
      .then(() => {
      })
      .catch(() => {
      })
    },
    getAccount() {
        this.$http.get('/accounts/Get_Account/' + this.$route.query.accountId)
        .then((response) => {
            this.account = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },
  }
}
</script>

<style>

</style>