<template>
<div class="row g-gs">
<div class="col-lg-12">
	<div class="card h-100">
		<div class="card-inner">
			<div class="row">
				<div class="col-md-12">
					<div class="float-right">
						<div style="width:300px;">
							<a-month-picker format="MM/YYYY" :value="report.reportDate"  class="mr-2" style="width:200px; float:left;" v-model="report.reportDate" /> 
							<button :disabed="!report.sendToEmailAddresses  && !report.reportTypeId" :class="{'disabled': (!report.sendToEmailAddresses  && !report.reportTypeId)}"  class="btn btn-dark btn-sm " @click="testReport()">Send Now</button>
						</div>
					</div>
					<h5 class="card-title"><em class="icon ni ni-file"/> Reports</h5>
				</div>
			</div>

		<div class="row">
			<div class="col-3">
				<div class="form-group">
					<span class="pt-1 form-label float-right" for="accountOwner"><em class="icon ni ni-user"/> Report Type</span>
				</div>
			</div>
			<div class="col-7">
					<div class="form-group">
					<div class="form-control-wrap">
						<a-select v-model="report.reportTypeId"
							show-search
							class="invisibox"
							placeholder="Select a Report Type"
							option-filter-prop="children"
							:filter-option="filterOption"
							style="width: 100%;">
								<a-select-option v-for="rt in reportTypes" v-bind:key="rt.id">
									{{ rt.text }}
								</a-select-option>
						</a-select>
					</div>
				</div>
			</div>
		</div>
	<div class="row">
		<div class="col-3">
			<div class="form-group">
					<span class="pt-1 form-label float-right" for="accountOwner"><em class="icon ni ni-user"/> Email Addresses</span>
			</div>
		</div>
		<div class="col-8">
				<a-select mode="tags" style="width: 100%" placeholder="Email addresses" :token-separators="[',']" class="invisibox" v-model="report.sendToEmailAddresses">
				</a-select>
				</div>
					<div class="col-12">
			<button :disabed="!report.sendToEmailAddresses  && !report.reportTypeId" v-on:click="saveReport()"  :class="{'disabled': (!report.sendToEmailAddresses  && !report.reportTypeId)}"   class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Add Scheduled Report</button>
		</div> 
	</div>
	<div class="row">
			<div class="col-12">
				<div class="row g-3 align-center">
					<div class="col-lg-3">
						
					</div>
					<div class="col-lg-9">
						
					</div>
				</div>
			</div> 
			</div> 
			<hr class="preview-hr">
			<div class="row">
				<div class="col-12">
					<vue-good-table
						:columns="columns"
						:rows="currentReports"
						:sort-options="{
							enabled: false
						}"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'createdDate'">{{ $moment(props.row.createdDate).format("H:mm a DD/MM/YYYY") }}</span>
							<div v-else-if="props.column.field == 'actions'">
								<button class="btn btn-danger btn-sm" @click="deleteReport(props.row)"><i class="icon ni ni-trash"></i></button>
							</div>
						</template>
					</vue-good-table>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
</template>

<script>import moment from "moment"

export default {
props: {
id: Number
},
data() {
return {
	reportTypes: [],
	reportSchedules: [],
	filterOption: '',
	report: {accountId: this.id, reportDate: moment().subtract(1, 'months')},
	reportTemplate:{accountId: this.id, reportTypeId: null},
	currentReports: [],
	columns: [
		{ label: 'Report Type', field: 'reportType'},
		{ label: 'Sending To', field: 'sendToEmailAddresses'},
		{ label: 'Created Date', field: 'createdDate'},
		{ label: 'Created By User', field: 'createdByUser'},
		{ label: 'Actions', field: 'actions', thClass: 'text-center', tdClass: 'text-center'}
	]
}
},
created() {
this.getList()
this.getCurrentReports()
},
methods: {
testReport() {
	this.report.sendToEmailAddresses = this.report.sendToEmailAddresses.toString()
	
	this.$http.post('/reports/Test_tenant_Automated_Report', this.report)
	.then(() => {
		this.getCurrentReports()
		this.$message.success('Test Report Sent') 
	})
	.catch(() => {
	this.$message.error('There has been an error')
	})
},
saveReport() {
	this.report.sendToEmailAddresses = this.report.sendToEmailAddresses.toString()
	
	this.$http.post('/reports/Add_tenant_Automated_Report', this.report)
	.then(() => {
		this.getCurrentReports()
		this.$message.success('Report Schedule Added')
		this.report = JSON.parse(JSON.stringify(this.reportTemplate))
	})
	.catch(() => {
	this.$message.error('There has been an error')
	})
},
getList() {
	this.$http.get('/lists/Get_List/reportTypes')
	.then((response) => {
		this.reportTypes = response.data
	})
	.catch(() => {
	this.$message.error('There has been an error')
	})
},
getCurrentReports() {
	this.$http.get('/reports/Get_tenant_Automated_Report/' + this.id)
	.then((response) => {
		this.currentReports = response.data
	})
	.catch(() => {
	this.$message.error('There has been an error')
	})
},
deleteReport(props) {
	this.$http.post('/reports/Delete_tenant_Automated_Report', props)
	.then(() => {
		this.$message.success('Report Schedule Deleted')
		this.getCurrentReports()
	})
	.catch(() => {
	this.$message.error('There has been an error')
	})
}
}
}	
</script>

<style>

</style>